
.slick-dots li button:before {
    color: #7B7B7B;
    opacity: 1;
    font-size: 8px;
}
.slick-dots li.slick-active button:before {
    color: #E7E7E7;
    opacity: 1;
}
.slick-dots li {
    margin: 0;
    width: 15px;
}

